import * as React from "react"

const BeeComponent = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
    <svg
        width="297mm"
        height="210mm"
        viewBox="0 0 297 130"
        id="svg5"
        xmlSpace="preserve"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g id="layer1">
            <g
                id="g4693"
                transform="matrix(.00257 .0077 -.0077 .00257 275.544 28.667)"
            >
                <path
                    className="st0"
                    id="rect4681"
                    d="M156.884 246.973h198.242v47.094H156.884z"
                />
                <path
                    className="st0"
                    id="rect4683"
                    d="M156.884 315.861h198.242v47.094H156.884z"
                />
                <path
                    className="st0"
                    d="M161.443 387.245c11.567 36.775 43.999 64.239 83.416 68.662v26.727c0 6.158 4.989 11.148 11.147 11.148s11.146-4.99 11.146-11.148v-26.727c39.418-4.423 71.848-31.887 83.415-68.662Z"
                    id="path4685"
                />
                <path
                    className="st0"
                    d="M152.144 62.6c5.954 1.826 12.089 1.066 17.203-1.632 14.299 12.155 23.598 24.164 29.732 34.551-25.491 17.94-42.195 47.525-42.195 81.068v47.094h198.242v-47.094c0-33.543-16.704-63.128-42.195-81.068 6.134-10.387 15.422-22.396 29.722-34.551 5.114 2.698 11.248 3.458 17.214 1.632 11.986-3.662 18.722-16.352 15.071-28.327-3.664-11.974-16.352-18.722-28.339-15.058-11.974 3.662-18.71 16.34-15.058 28.326.272.873.68 1.656 1.043 2.472-15.298 13.097-25.571 26.274-32.442 37.92-13.302-6.634-28.259-10.466-44.134-10.466-15.876 0-30.833 3.832-44.146 10.466-6.872-11.646-17.145-24.811-32.431-37.92.362-.816.771-1.599 1.031-2.472 3.662-11.986-3.084-24.664-15.059-28.326-11.986-3.664-24.664 3.084-28.326 15.058-3.665 11.976 3.082 24.665 15.067 28.327z"
                    id="path4687"
                />
                <path
                    className="st0"
                    d="M108.192 433.466c-21.705 0-41.231-8.721-55.508-22.94-14.232-14.277-22.952-33.805-22.952-55.52 0-7.132.953-14.072 2.778-20.616 18.484 17.396 43.318 28.055 70.726 28.055 14.05 0 27.443-2.836 39.644-7.916v-27.702c-11.646 6.848-25.13 10.841-39.644 10.841-21.704 0-41.242-8.778-55.507-22.998a66.375 66.375 0 0 1-4.014-4.366 76.75 76.75 0 0 1-13.631-22.748c-3.424-8.776-5.306-18.336-5.306-28.349 0-21.704 8.72-41.287 22.952-55.507 14.265-14.231 33.803-22.997 55.507-22.997 14.515 0 27.999 3.991 39.644 10.841v-27.68a102.72 102.72 0 0 0-39.644-7.938C46.198 155.972 0 202.169 0 259.208c0 19.233 5.261 37.217 14.424 52.639-6.101 13.132-9.468 27.749-9.468 43.159 0 57.05 46.198 103.238 103.236 103.238 24.72 0 47.378-8.698 65.146-23.156a113.107 113.107 0 0 1-14.775-19.912c-13.664 11.44-31.161 18.29-50.371 18.29z"
                    id="path4689"
                />
                <path
                    className="st0"
                    d="M512 259.208c0-57.039-46.187-103.236-103.226-103.282a102.776 102.776 0 0 0-39.655 7.938v27.68c11.646-6.85 25.14-10.841 39.655-10.841 21.705 0 41.232 8.766 55.508 22.997 14.22 14.22 22.94 33.804 22.94 55.507 0 10.013-1.882 19.573-5.306 28.349a76.522 76.522 0 0 1-13.62 22.748 70.17 70.17 0 0 1-4.014 4.366c-14.276 14.22-33.803 22.998-55.508 22.998-14.515 0-28.009-3.992-39.655-10.841v27.702a103.025 103.025 0 0 0 39.655 7.916c27.409 0 52.231-10.66 70.715-28.055 1.837 6.543 2.778 13.484 2.778 20.616 0 21.715-8.72 41.243-22.94 55.52-14.276 14.22-33.804 22.94-55.507 22.94-19.221 0-36.708-6.85-50.371-18.291-4.218 7.234-9.196 13.88-14.776 19.912 17.758 14.458 40.427 23.156 65.147 23.156 57.039 0 103.225-46.187 103.225-103.238 0-15.41-3.367-30.027-9.457-43.159C506.749 296.425 512 278.441 512 259.208Z"
                    id="path4691"
                />
            </g>
            <path
                style={{
                    fill: "none",
                    stroke: "currentcolor",
                    strokeWidth: 0.400943,
                    strokeDasharray: ".400943,.801886",
                    strokeDashoffset: 0,
                    strokeOpacity: 1,
                }}
                d="M-45.352 76.52s46.456 38.592 80.141 30.26c42.094-10.41 45.728-38.358 105.527-17.437 72.135 25.238 93.347 7.067 91.338-17.73-1.684-20.788 24.77-33.401 39.963-39.512"
                id="path8198"
            />
        </g>
        <style type="text/css" id="style4679">
            {".st0{fill:currentColor}"}
        </style>
    </svg>
)

export default BeeComponent
